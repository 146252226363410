import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import deferredPrompt from '../utils/deferredPrompt';
import { useEmbed } from './Embed';
import { eventServiceToken, sendEvent } from '../utils/eventsService';
import { useAuth } from './Auth';
import { useScreen } from './UseScreenContext';
import { isAppleMobile } from '../utils/PWA';
import { usePartner } from './PartnerContext';

const PWAContext = createContext();

const usePWA = () => useContext(PWAContext);

function PWAContextProvider({ children }) {
	const isAppleMobileRef = useRef(isAppleMobile());
	const { authTokens } = useAuth();
	const { isComputer } = useScreen();
	const [isPWA, setIsPWA] = useState(false);
	const [isPWACompatible, setIsPWACompatible] = useState(false);
	const { isEmbed } = useEmbed();
	const { partner } = usePartner();

	const [deferredPromptState, setDeferredPromptState] = useState();

	useEffect(() => {
		let cleanup;
		if (!isEmbed) {
			if (deferredPrompt.event) {
				setDeferredPromptState(deferredPrompt.event);
			} else {
				cleanup = deferredPrompt.subscribe(setDeferredPromptState);
			}
		}

		if (
			(window.matchMedia('(display-mode: standalone)').matches ||
				navigator.standalone) &&
			!partner
		) {
			setIsPWA(true);
		}

		return () => {
			if (typeof cleanup === 'function') {
				cleanup();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			(deferredPromptState?.event && !isComputer && !partner) ||
			(isAppleMobileRef.current && !partner)
		) {
			setIsPWACompatible(true);
		}
	}, [deferredPromptState, isComputer, partner]);

	useEffect(() => {
		let cleanup;

		if (isPWA && authTokens && !localStorage.getItem('pwa-init-event')) {
			cleanup = eventServiceToken.subscribe(() => {
				localStorage.setItem('pwa-init-event', true);
				sendEvent({ gaName: 'pwa-init-event' });
			});
		}

		return () => {
			if (typeof cleanup === 'function') {
				cleanup();
			}
		};
	}, [isPWA, authTokens]);

	const value = useMemo(
		() => ({ deferredPromptState, isPWA, isPWACompatible }),
		[deferredPromptState, isPWA, isPWACompatible]
	);

	return <PWAContext.Provider value={value}>{children}</PWAContext.Provider>;
}

export { PWAContextProvider, usePWA };
