import React, { createContext, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const PartnerContext = createContext();

const usePartner = () => useContext(PartnerContext);

function PartnerProvider({ children }) {
	const [searchParams] = useSearchParams();
	const partner = useMemo(
		() => searchParams.get('partner') || null,
		[searchParams]
	);

	const value = useMemo(() => ({ partner }), [partner]);

	return (
		<PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>
	);
}

export { PartnerProvider, usePartner };
